declare global {
  interface Window {
    agree: () => void;
  }
}

function getUrl() {
  const url = new URL(window.location.href)
  const params = url.searchParams
  return params.get('url') ?? ''
}

window.agree = () => {
  location.replace(getUrl())
}

export {}
